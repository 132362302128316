import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
    <footer className="footer mt-auto py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md">
            <span className="d-block">&copy; 2020 {data.site.siteMetadata.title}</span>
            <small className="d-block mb-0">{data.site.siteMetadata.description}</small>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer