import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Navbar, Nav, Container } from 'react-bootstrap'

const Navigation = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        file(relativePath: {eq: "logo.png"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" className="border-0">
      <Container>
        <Navbar.Brand href="/" className="col mr-0 p-0">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt={data.site.siteMetadata.title}
            style={{ height: '48px', width: '100%' }}
            imgStyle={{ objectFit: 'contain', objectPosition: 'left center' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <AnchorLink to="/#story" className="nav-link">About Us</AnchorLink>
            <AnchorLink to="/#solutions" className="nav-link">Why Time Lapse?</AnchorLink>
            <AnchorLink to="/#services" className="nav-link">Our Services</AnchorLink>
            <AnchorLink to="/#projects" className="nav-link">Featured Projects</AnchorLink>
            <AnchorLink to="/#clientelle" className="nav-link">Our Clients</AnchorLink>
          </Nav>
          <Nav className="my-4">
            <Link to="/contact/" className="btn btn-lg btn-outline-primary d-block d-lg-none my-2 my-sm-0 text-uppercase">Contact us</Link>
          </Nav>
        </Navbar.Collapse>
        <Nav className="col px-0 d-none d-lg-flex">
          <Link to="/contact/" className="btn btn-outline-primary ml-auto my-2 my-sm-0 text-uppercase">Contact us</Link>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Navigation