import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Jumbotron = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
    <div className="jumbotron px-0">
      {/* Text */}
      {/* <div className="container">
        <h1 className="display-3">Card title</h1>
        <p>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
      </div> */}
      {/* Image */}
      {/* <div className="card border-0 w-100 img-gradient">
        <img className="card-img" src="http://www.timelapse.com.my/wp-content/uploads/2017/09/TRX-Site-Progress-Aerial-Photograph.jpg" alt={data.site.siteMetadata.title} />
        <div className="card-img-overlay container">
          <h1 className="card-title">{data.site.siteMetadata.title}</h1>
          <p className="card-text">{data.site.siteMetadata.description}</p>
        </div>
      </div> */}
      {/* Video */}
      <div className="d-none d-md-block embed-responsive embed-responsive-21by9">
        <div className="overlay d-none d-md-block">
          <span className="corner-triangle"></span>
          Digital visual documentation<br />specialist in construction
        </div>
        <span className="corner-triangle"></span>
        <iframe width="640" height="360" src="https://www.youtube.com/embed/mdRvUgwQ__I?modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title={data.site.siteMetadata.title} allowFullScreen></iframe>
      </div>

      <div className="d-block d-md-none embed-responsive embed-responsive-4by3">
        <div className="overlay d-none d-md-block">
          <span className="corner-triangle"></span>
          Digital visual documentation<br />specialist in construction
        </div>
        <span className="corner-triangle"></span>
        <iframe width="480" height="360" src="https://www.youtube.com/embed/mdRvUgwQ__I?modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title={data.site.siteMetadata.title} allowFullScreen></iframe>
      </div>
    </div>
  )
}

export default Jumbotron