import React from 'react'
import { Header, Nav, Footer } from '.'

const Layout = ({ children }) => {
  return (
    <>
      <Header>
        <Nav />
      </Header>
      
      <main>
        {children}
      </main>

      <Footer />
    </>
  )
}

export default Layout