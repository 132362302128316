import React from 'react'

class Loader extends React.Component {
  render() {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
}

export default Loader