import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Map = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
    <section className="container-fluid location">
      <div className="row">
        <div className="col-12 col-md-6 pt-5 pt-md-0 px-md-0 mr-lg-5">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe className="embed-responsive-item" width="1200" height="900" id="gmap_canvas" src="https://maps.google.com/maps?q=timelapse%20production&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title={data.site.siteMetadata.title}></iframe>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-offset-lg-2 my-5 my-md-auto mr-auto px-5">
          <div className="card border-0 bg-transparent">
            <div className="card-header px-0">
              <small className="text-uppercase">Our location</small>
            </div>
            <div className="card-body pt-0 px-0">
              <h3 className="card-title">We're based in Subang Jaya</h3>
              <p className="lead">Our office is located at The Empire Tower in downtown area of Subang Jaya. Come visit us.</p>
              <address>
                <strong>{data.site.siteMetadata.title}</strong><br />
                A-5-10, Empire Tower, <br />
                SS16/1, Subang Jaya, <br />
                Selangor Darul Ehsan, Malaysia<br />
                <abbr title="Phone">P:</abbr> +60 19-399 4572
              </address>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Map